/**
 * 参数添加签名信息
 *
 * 需安装js-md5
 * npm i -S js-md5
 * npm i -D @types/js-md5
 *
 * 密钥不在网络进行传输，而是作为本地加密使用，此方式仅能在开发中使用，禁止用于线上生产环境
 */
import md5 from 'js-md5'

const fillSign = (params: any) => {
    // params.username = 'sxkt'
    // params.app_secret = '2'
    params.username = 'liuyao'
    params.app_secret = 'b3f3f17618faaf215dc9464fdde46bc1'
    params.timestamp = Date.now()
    params.nonce = 'test'
    let keys = []
    for (const key in params) {
        keys.push(key)
    }
    keys = keys.sort()
    const paramStr = []
    for (const k of keys) {
        paramStr.push(`${k}=${params[k]}`)
    }
    params.sign = md5(paramStr.join('&')).toUpperCase()
    delete params.app_secret
}
export default fillSign
