import request from '@/utils/request'

// https://ltld.sxktqx.com/efdata/list?times=20220613145000
const electricDataList = (params: any) => {
    return request.get('/efdata/list', params)
    .then((data) => {
        // console.log(data)
        if (data.length > 0) {
            return data
        }
        return []
    }).catch((err) => {
        console.log(err)
        return []
    })
}
const electricDataPage = (params: any) => {
    return request.get('/efdata/page', params)
    .then((data) => {
        // console.log(data)
        if (data.records.length > 0) {
            return data
        }
        return []
    }).catch((err) => {
        console.log(err)
        return []
    })
}
export default {electricDataList, electricDataPage}
