import request from '@/utils/request'
import requestGet from '@/utils/requestGet'

// http://124.114.232.106:8787
// /api/thunder/circle?
// timeRange=20220527072933,20220527122933&
// lon=105.268&
// lat=33.067&
// radius=500000
const thunderData = (params: any) => {
    return requestGet.get('/thunder/circle', params)
    .then((data) => {
        // console.log(data)
        if (data.length > 0) {
            return data
        }
        return []
    }).catch((err) => {
        console.log(err)
        return []
    })
}
export default {thunderData}
